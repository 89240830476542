import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

import { api } from '../../services';

const Footer = ({ viewLinks = false, complete = true }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [labels, setLabels] = useState(null);
  const [subContents, setSubContents] = useState(null);

  const currentLang = useSelector(({ config }) => config.language);

  const { fetchFooterData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      setLoading(true);
      try {
        // if (!ignore) {
        const { labels, subcontents } = await fetchFooterData(currentLang);
        setLabels(labels);
        setSubContents(subcontents);
        // }
      } catch (e) {
        console.debug('err', e.message);
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (viewLinks) fetchData(ignore);
    // return () => {
    //   ignore = true;
    // };
  }, [viewLinks]);
  return (
    <footer>
      {viewLinks && !loading && !error && (
        <div className="footer-links">
          {/* links de redireccion */}
          <div className="row justify-content-center pb-4 m-0">
            <div className="col-12 col-md-3 text-center">
              <Link to="/contacto">
                {labels.labelContactLink || 'labelContactLink'}
              </Link>
            </div>

            {labels.LabelPrivacyLink && subContents && (
              <div className="col-12 col-md-3 text-center">
                <Link
                  to={`${
                    subContents &&
                    subContents.find(
                      sub => sub.title === labels.LabelPrivacyLink
                    )
                      ? `/contenidos/nosotros/${
                          subContents.find(
                            sub => sub.title === labels.LabelPrivacyLink
                          ).id
                        }`
                      : '#'
                  }`}
                >
                  {labels.LabelPrivacyLink || 'LabelPrivacyLink'}
                </Link>
              </div>
            )}

            {labels.labelTermsLink && subContents && (
              <div className="col-12 col-md-3 text-center">
                <Link
                  className="no-border"
                  to={`${
                    subContents &&
                    subContents.find(sub => sub.title === labels.labelTermsLink)
                      ? `/contenidos/nosotros/${
                          subContents.find(
                            sub => sub.title === labels.labelTermsLink
                          ).id
                        }`
                      : '#'
                  }`}
                >
                  {labels.labelTermsLink || 'labelTermsLink'}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
      {complete && (
        <div className="footer">
          <img
            className="logos-footer d-sm-block d-md-none d-lg-none"
            src="/img/footer-logos-320.svg"
            alt="Logos footer"
          />
          <img
            className="logos-footer d-none d-sm-none d-md-block d-lg-none"
            src="/img/footer-logos-768.svg"
            alt="Logos footer"
          />
          <img
            className="logos-footer d-none d-sm-none d-md-none d-lg-block"
            src="/img/footer-logos-980.svg"
            alt="Logos footer"
          />
        </div>
      )}
    </footer>
  );
};

export default Footer;
