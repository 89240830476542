import React from 'react';

const CreativeCommons = () => {
  return (
    <a
      className="creative-common-container"
      href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
      target="_blank"
    >
      <img src="/img/creative_commons/cc.svg" alt="Icono cc" />
      <img src="/img/creative_commons/by.svg" alt="Icono by" />
      <img src="/img/creative_commons/nc.svg" alt="Icono nc" />
      <img src="/img/creative_commons/sa.svg" alt="Icono sa" />
    </a>
  );
};

export default CreativeCommons;
