import React from 'react';
import { Link } from 'gatsby';

const MenuHamburger = ({ isActive, to, previous }) => {
  const currentStyle = `p-2 ${
    isActive || false ? 'fas fa-times' : 'fas fa-bars'
  }`;
  return (
    <div>
      <Link className="btn ml-auto" to={to} state={{ prevPath: previous }}>
        <i className={currentStyle} />
      </Link>
    </div>
  );
};

export default MenuHamburger;
