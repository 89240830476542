import axios from 'axios';
import { camelCaseToDash } from './utils';

/**
 * Crea una nueva instancia de axios, si viene un token,
 * la agrega al header
 * @param {String} token
 */
const createInstance = (token = '', endopintURL = 'graphql') => {
  let api = null;
  if (token) {
    api = axios.create({
      baseURL: `${process.env.STRAPI_URL}/${endopintURL}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    api = axios.create({
      baseURL: `${process.env.STRAPI_URL}/${endopintURL}`,
    });
  }

  return api;
};

export default () => {
  return {
    fetchSplashData,
    fetchBeforeStartingData,
    fetchMenuData,
    fetchStepData,
    fetchTaskData,
    fetchStaticContentData,
    fetchDetailTaskData,
    fetchGlosarioData,
    fetchResearchMoreData,
    fetchLoginData,
    mutationLogin,
    fetchPremiumMenuContentData,
    fetchPremiumContent,
    fetchForgotPasswordData,
    mutationForgotPassword,
    fetchPasswordRecoveryData,
    mutationPasswordRecovery,
    getHeatmapsLocation,
    fetchErrorMessagePremiumData,
    insertHeatMapData,
    fetchMyCountData,
    updateMyCountData,
    updatePasswordProfile,
    fetchPlansData,
    restPaySelected,
    fetchPlanSubscribe,
    fetchMyPlan,
    fetchGroupMembers,
    addGroupMembers,
    removeGroupMembers,
    unsubscribePlan,
    fetchLabelsCheckIn,
    registerNewUser,
    statusPayPlan,
    statusPlan,
    isPlanActive,
    fetchLabelsInactivePlan,
    addComment,
    getComments,
    fetchLabelsComments,
    fetchLandingPageData,
    assignPlanFree,
    fetchLogos,
    fetchLabelContact,
    sendContactMessage,
    fetchFooterData,
    updateMyProgress,
    fetchLandingPageComponentsData,
  };
};

const fetchSplashData = async () => {
  const query = `
  {
    languages {
      name
      abbr: abbreviation
    }
    splash {
      contents {
        title
        paragraph: content
        language {
          abbr: abbreviation
        }
      }
    }
    interfaceContents(where: { title: "splash" }) {
      contents {
        language {
          abbr: abbreviation
        }
        contents: sub_contents {
          key
          value
        }
      }
    }
  }`;

  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;

    const filteredData = response.languages.map(val => {
      const { title, paragraph } =
        response.splash.contents.find(
          contentsVal => contentsVal.language.abbr === val.abbr
        ) || null;
      const interfaces =
        response.interfaceContents[0].contents.find(
          interfacesVal => interfacesVal.language.abbr === val.abbr
        ).contents || null;

      const btn = Array.isArray(interfaces)
        ? interfaces.find(val => val.key === 'btn').value
        : null;
      const slide = Array.isArray(interfaces)
        ? interfaces.find(val => val.key === 'slide').value
        : null;
      return {
        language: val,
        contents: { title, paragraph },
        interfaces: { btn, slide },
      };
    });
    return filteredData;
  } catch (e) {
    errorHandler(e);
  }
};

/**
 * Obtiene los datos para la pagina 'antes de comenzar'
 * @param {String} language
 *
 *
 * @returns {Object} obj
 * @returns {Object} obj.language
 * @returns {String} obj.language.name
 * @returns {String} obj.language.abbreviation
 * @returns {Object} obj.contents
 * @returns {String} obj.contents.title
 * @returns {String} obj.contents.paragraph
 * @returns {Object} obj.interfaces
 * @returns {String} obj.interfaces.btn
 */
const fetchBeforeStartingData = async language => {
  const query = `
    {
      beforeStarting {
        contents {
          language {
            abbr: abbreviation
          }
          title
          paragraph: content
        }
      }
      interfaceContents(where: { title: "antes-de-comenzar" }) {
        contents {
          language {
            abbr: abbreviation
          }
          contents: sub_contents {
            key
            value
          }
        }
      }
    }`;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const { title, paragraph } =
      response.beforeStarting.contents.find(
        contentsVal => contentsVal.language.abbr === language
      ) || null;
    const interfaces =
      response.interfaceContents[0].contents.find(
        interfacesVal => interfacesVal.language.abbr === language
      ).contents || null;
    const btn = Array.isArray(interfaces)
      ? interfaces.find(val => val.key === 'btn').value
      : null;
    return {
      contents: {
        title,
        paragraph,
      },
      interfaces: {
        btn,
      },
    };
  } catch (e) {
    errorHandler(e);
  }
};

/**
 * Obtiene los datos para la pagina 'menu'
 * @param {String} language
 *
 * @returns {Object} obj
 * @returns {Object} obj.languages
 * @returns {String} obj.languages.name
 * @returns {String} obj.languages.abbr
 * @returns {Array<{uid: {String} title: {String} subMenu: {Array<{id: {Number} title: {String}}>}}>} obj.menu
 * @returns {Array<{letter: {String} hasWords: {Boolean}}>} obj.glossary
 * @returns {Object} obj.login
 * @returns {Object} obj.login.title
 * @returns {String} obj.login.title.login
 * @returns {String} obj.login.title.logout
 */
const fetchMenuData = async language => {
  const query = `
  query Menu {
    languages {
      name
      abbr: abbreviation
    }
    staticContents {
      uid_name
      contents {
        title
        subMenu: sub_contents {
          id
          title
        }
        language {
          abbr: abbreviation
        }
      }
    }
    glossary {
      contents {
        language {
          abbr: abbreviation
        }
        letters {
          letter
          words {
            word
          }
        }
      }
    }
    labelsMenu: interfaceContents(where: { title: "menu" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
    conf: parametersConfs{
      key
      value
    }
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const config = {};
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const filteredData = response.staticContents
      .map(val => ({ uid: val.uid_name, ...val.contents[0] }))
      .filter(val => val.language.abbr === language)
      .filter(val => val.uid !== 'glossary')
      .filter(val => val.uid !== 'premium-content')
      .map(val => ({ uid: val.uid, title: val.title, subMenu: val.subMenu })); //se remueve la llave language de los objetos, ya que no es necesaria.

    // Glossary menu goes here
    const subMenuGlossary = response.glossary.contents
      .filter(content => content.language.abbr === language)
      .map(val => val.letters)[0]
      .map(({ letter, words }) => ({
        letter,
        hasWords: words.length > 1 ? true : false,
      }));
    const glossaryMenu = response.staticContents
      .map(val => ({ uid: val.uid_name, ...val.contents[0] }))
      .filter(val => val.language.abbr === language)
      .filter(val => val.uid === 'glossary')
      .map(val => ({
        uid: val.uid,
        title: val.title,
        subMenu: subMenuGlossary,
      }))[0];

    const loginMenu = response.labelsMenu[0].contents.find(
      val => val.language.abbr === language
    ).sub_contents;
    // console.debug("labelsSubMenu: ", loginMenu)

    response.conf.map(conf => (config[conf.key] = conf.value));

    return {
      languages: response.languages,
      menu: filteredData,
      glossary: glossaryMenu,
      loginMenu: loginMenu,
      config: config,
    };
  } catch (e) {
    errorHandler(e);
  }
};

/**
 * Obtiene los datos para la pagina 'etapa'
 * @param {String} language
 * @param {Number} stepId - (no used)
 *
 * @returns {Object} obj
 * @returns {Object} obj.content
 * @returns {Object} obj.content.step
 * @returns {Number} obj.content.step.id
 * @returns {String} obj.content.step.name
 * @returns {String} obj.content.step.theme
 * @returns {Object} obj.content.task
 * @returns {Number} obj.content.task.taskNumber
 * @returns {String} obj.content.task.name
 * @returns {String} obj.content.task.icon
 * @returns {String} obj.label
 * @returns {String} obj.label.modal
 * @returns {String} obj.label.stepModal
 */
const fetchStepData = async (language, stepId) => {
  const query = `
  query Step {
    tasks {
      number_task
      icon {
        name
        url
      }
      contents {
        name
        language {
          abbr: abbreviation
        }
      }
      step {
        id
        theme: class_selector
        contents {
          name
          language {
            abbr: abbreviation
          }
        }
      }
    }
    labels: interfaceContents(where: { title: "varios" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }  
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', {
      query,
    });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }
    const labels = {};

    const filteredData = response.tasks.map(val => {
      const task = val.contents.filter(
        content => content.language.abbr === language
      )[0];
      const step = val.step.contents.filter(
        content => content.language.abbr === language
      )[0];

      return {
        step: {
          id: val.step.id,
          name: step.name,
          theme: camelCaseToDash(val.step.theme),
        },
        task: {
          taskNumber: val.number_task,
          name: task.name,
          icon: val.icon ? `${process.env.STRAPI_URL}${val.icon.url}` : '',
        },
      };
    });

    const modalLabel = response.labels[0].contents
      .find(content => content.language.abbr === language)
      .sub_contents.find(content => content.key === 'MessageModal');

    const stepModal = response.labels[0].contents
      .find(content => content.language.abbr === language)
      .sub_contents.find(content => content.key === 'MessageStepModal');

    const interfaceData =
      response.labels.length > 0
        ? response.labels[0].contents.find(
            val => val.language.abbr === language
          ).sub_contents
        : {};
    console.debug('interfaceData', interfaceData);
    interfaceData.map(label => (labels[label.key] = label.value));

    return {
      content: filteredData,
      label: {
        modal: modalLabel ? modalLabel.value : '',
        stepModal: stepModal ? stepModal.value : '',
      },
      labels: labels,
    };
  } catch (e) {
    errorHandler(e);
  }
};

const fetchTaskData = async (taskNumber, language) => {
  const query = `
  query Task($number_task: String!) {
    tasks(where: { number_task: $number_task }) {
      number_task
      icon {
        url
      }
      contents {
        name
        what_is
        language {
          abbr: abbreviation
        }
      }
      step {
        id
        theme: class_selector
      }
      content_pro: contenidos_premiums{
        id
        title
        uid_title
      }
    }
    labels: interfaceContents(where: { title: "varios" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', {
      query,
      variables: { number_task: taskNumber },
    });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const dataFiltered = response.tasks.map(val => {
      const labels = {};
      const content = val.contents.filter(
        content => content.language.abbr === language
      )[0];
      const modalLabel = response.labels[0].contents
        .find(content => content.language.abbr === language)
        .sub_contents.find(content => content.key === 'MessageModal');

      const interfaceData =
        response.labels.length > 0
          ? response.labels[0].contents.find(
              val => val.language.abbr === language
            ).sub_contents
          : {};
      console.debug('interfaceData', interfaceData);

      interfaceData.map(label => (labels[label.key] = label.value));
      return {
        taskNumber: val.number_task,
        name: content.name,
        whatIs: content.what_is,
        icon: val.icon ? `${process.env.STRAPI_URL}${val.icon.url}` : '',
        theme: camelCaseToDash(val.step.theme),
        stepId: val.step.id,
        modal: modalLabel ? modalLabel.value : '',
        contenidoPro: val.content_pro,
        labels: labels,
      };
    })[0];
    return dataFiltered;
  } catch (e) {
    errorHandler(e);
  }
};

const fetchStaticContentData = async (language, staticContent, subContent) => {
  const query = `
  query StaticContent($uid_name: String!) {
    staticContents(where: { uid_name: $uid_name}) {
      name
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          id
          title
          content
        }
      }
    }
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', {
      query,
      variables: { uid_name: staticContent },
    });
    const response = data.data.staticContents;
    if (response.errors) {
      throw response.errors;
    }

    const dataFiltered = response
      .filter(val => val.contents[0].language.abbr === language)
      .map(val => ({ uid: val.uid_name, ...val.contents[0] }))
      .reduce((obj, val) => ({ ...obj, ...val }), {});

    const subContentData = dataFiltered.sub_contents
      .filter(val => val.id === subContent)
      .reduce((obj, val) => ({ ...obj, ...val }), {});
    return { content: subContentData.content, title: response[0].name };
  } catch (e) {
    errorHandler(e);
  }
};

/**
 * Obtiene los datos para la pagina 'detalle tarea'
 * @param {String} taskId
 * @param {String} language
 * @param {String | Number} nextId
 *
 * @returns {Object} obj
 * @returns {Object} obj.labels
 * @returns {String} obj.label.<key>
 * @returns {Object} obj.content
 * @returns {Number} obj.content.numberTask
 * @returns {Number} obj.content.stepId
 * @returns {String} obj.content.theme
 * @returns {String} obj.content.name
 * @returns {String} obj.content.icon
 * @returns {Array<{numberTask: {Number} name: {String} theme: {String} icon: {String}}>} obj.content.relationalTasks
 * @returns {Object} obj.content.sections
 * @returns {String} obj.content.sections.whatIs
 * @returns {String} obj.content.sections.forWhat
 * @returns {String} obj.content.sections.expectedResults
 * @returns {Object} obj.content.block
 * @returns {String} obj.content.block.resources
 * @returns {String} obj.content.block.time
 * @returns {String} obj.content.block.people
 * @returns {String} obj.content.howTo
 * @returns {Object} obj.nextTask
 * @returns {Number} obj.nextTask.numberTask
 * @returns {Number} obj.nextTask.stepId
 */
const fetchDetailTaskData = async (taskId, language, nextId) => {
  const query = `
  query TaskDetail($task_number: String!, $next_task: ID!) {
    next_task: task(id: $next_task) {
      number_task
      step {
        id
      }
    }
    tasks(where: { number_task: $task_number }) {
      number_task
      step {
        id
        theme: class_selector
      }
      icon {
        url
      }
      contents {
        language {
          abbr: abbreviation
        }
        name
        what_is
        for_what
        for_what_url_video
        for_what_video_subtitle
        for_what_intro_image{
          url
        }
        for_what_credit_image{
          url
        }
        for_what_video_pro{
          title
          url_video
          url_subtitle
          image_intro{
            url
          }
          image_ending{
            url
          }
          replace_free
        }
        expected_results
        expected_result_url_video
        expected_result_video_subtitle
        expected_result_intro_image{
          url
        }
        expected_result_credit_image{
          url
        }expected_result_video_pro{
          title
          url_video
          url_subtitle
          image_intro{
            url
          }
          image_ending{
            url
          }
          replace_free
        }
        people_number
        time
        how_to {
          letter
          title
          content
          note
          url_video
          subtitle_video
          intro_image{
            url
          }
          credit_image{
            url
          }
          title_video_pro
          url_video_pro
          url_subtitle_video_pro
          image_intro_video_pro{
            url
          }
          image_ending_video_pro{
            url
          }
          replace_free_video_pro
        }
        no_infographic{
          url
        }
        infographic_mobile{
          url
        }
      }
      resources {
        id
        contents {
          name
          language {
            abbr: abbreviation
          }
        }
      }
      relationals_tasks {
        number_task
        contents {
          name
          language {
            abbr: abbreviation
          }
        }
        step {
          theme: class_selector
        }
        icon {
          url
        }
      }
      content_pro: contenidos_premiums{
        id
        title
        uid_title
      }
    }
    interfaceContents(where: { title: "detalle-tarea" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
    modalLabel: interfaceContents(where: { title: "varios" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }  
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', {
      query,
      variables: { task_number: taskId, next_task: nextId },
    });
    const resourcesOrderData = await axios.get(
      `${process.env.STRAPI_URL}/tasks/getOrder/${taskId}`
    );

    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const task = response.tasks.map(task => {
      const content = task.contents.filter(
        content => content.language.abbr === language
      )[0];
      const relationalsTasks = task.relationals_tasks.map(relTask => {
        const content = relTask.contents.filter(
          content => content.language.abbr === language
        )[0];
        return {
          numberTask: relTask.number_task,
          name: content.name,
          theme: camelCaseToDash(relTask.step.theme),
          icon: relTask.icon
            ? `${process.env.STRAPI_URL}${relTask.icon.url}`
            : '',
        };
      });
      const resourcesOrder = resourcesOrderData.data;
      // console.debug('resourcesOrder', resourcesOrder);

      let resources = resourcesOrder.map(order => {
        const resource = task.resources.find(
          o => order.resource_id === parseInt(o.id, 10)
        );
        return resource;
      });
      console.debug('resources 1', resources);
      resources = resources
        .map((resource, i) => {
          const res = resource.contents.find(
            content => content.language.abbr === language
          );
          return `${i + 1}. ${res.name}`;
        })
        .join('\n');
      console.debug('resources 2', resources);

      return {
        numberTask: task.number_task,
        stepId: parseInt(task.step.id, 10),
        theme: camelCaseToDash(task.step.theme),
        name: content.name,
        icon: task.icon ? `${process.env.STRAPI_URL}${task.icon.url}` : '',
        relationalsTasks: relationalsTasks,
        sections: {
          whatIs: content.what_is,
          forWhat: content.for_what,
          forWhatVideo: content.for_what_url_video,
          forWhatVideoSubtitle: content.for_what_video_subtitle,
          forWhatImgIntro: content.for_what_intro_image,
          forWhatImgCredit: content.for_what_credit_image,
          forWhatVideoPro: content.for_what_video_pro,
          expectedResults: content.expected_results,
          expectedResultsVideo: content.expected_result_url_video,
          expectedResultsVideoSubtitle: content.expected_result_video_subtitle,
          expectedResultsImgIntro: content.expected_result_intro_image,
          expectedResultsImgCredit: content.expected_result_credit_image,
          expectedResultsVideoPro: content.expected_result_video_pro,
        },
        block: {
          resources: resources,
          time: content.time,
          people: content.people_number,
        },
        howTo: content.how_to,
        no_infographic: content.no_infographic,
        infographic_mobile: content.infographic_mobile,
      };
    })[0];

    const labels = response.interfaceContents[0].contents
      .filter(content => content.language.abbr === language)[0]
      .sub_contents.reduce(
        (obj, val) => ({
          ...obj,
          [val.key]: val.value,
        }),
        {}
      );

    const nextTask = response.next_task
      ? {
          numberTask: response.next_task.number_task,
          stepId: parseInt(response.next_task.step.id, 10),
        }
      : null;

    const modalLabel = response.modalLabel[0].contents
      .find(content => content.language.abbr === language)
      .sub_contents.find(content => content.key === 'MessageModal');

    return {
      label: {
        ...labels,
        modal: modalLabel ? modalLabel.value : '',
      },
      content: task,
      nextTask: nextTask,
      contentPro: response.tasks[0].content_pro,
    };
  } catch (e) {
    errorHandler(e);
  }
};

const fetchGlosarioData = async language => {
  const query = `
  query Glosario {
    glossary {
      contents {
        language {
          abbr: abbreviation
        }
        letters {
          letter
          words {
            word
            definition
          }
        }
      }
    } 
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }
    const dataFiltered = response.glossary.contents.filter(
      content => content.language.abbr === language
    )[0];
    return dataFiltered.letters;
  } catch (e) {
    errorHandler(e);
  }
};

const fetchResearchMoreData = async (taskId, language) => {
  const query = `
  query ResearchMore($task_number: String!) {
    tasks(where: { number_task: $task_number }) {
      contents {
        language {
          abbr: abbreviation
        }
        name
      }
      step {
        class_selector
      }
      aditional_links {
        title
        url
      }
      bibliography {
        type: __typename
        ... on ComponentBibliografiaLink {
          title
          url
        }
        ... on ComponentBibliografiaConferencePaper {
          author
          year
          title
          conference_name
          city
          state
          country
          date
          published_place
          editorial
          url
        }
        ... on ComponentBibliografiaInforme {
          author
          year
          title
          report_number
          city
          state
          country
          publisher
          url
        }
        ... on ComponentBibliografiaChapterBook {
          author
          year
          editorial
          title_chapter
          title_book
          url
          pages
        }
        ... on ComponentBibliografiaBook {
          author
          year
          title
          city
          state
          country
          editorial
          url
        }
        ... on ComponentBibliografiaChapterThesis {
          author
          year
          title_chapter
          title_thesis
          pages
          university
          url
        }
        ... on ComponentBibliografiaThesis {
          author
          year
          title
          university
          url
        }
        ... on ComponentBibliografiaThesis {
          author
          year
          title
          university
          url
        }
        ... on ComponentBibliografiaPaper {
          author
          year
          title_paper
          title_journal
          volume
          pages
          doi
          url
        }
        ... on ComponentBibliografiaCase {
          author
          year
          title
          city
          state
          country
          editorial
          url
        }
      }
      relationals_tasks {
        number_task
        contents {
          name
          language {
            abbr: abbreviation
          }
        }
        step {
          theme: class_selector
        }
        icon {
          url
        }
      }
    }
    relationalTasklabels: interfaceContents(where: { title: "detalle-tarea" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
    referencelabels: interfaceContents(where: { title: "investigar-mas" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
    modallabels: interfaceContents(where: { title: "varios" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', {
      query,
      variables: { task_number: taskId },
    });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const dataFiltered = response.tasks.map(task => {
      const content = task.contents.filter(
        content => content.language.abbr === language
      )[0];
      const relationalsTasks = task.relationals_tasks.map(relTask => {
        const content = relTask.contents.filter(
          content => content.language.abbr === language
        )[0];
        return {
          numberTask: relTask.number_task,
          name: content.name,
          theme: camelCaseToDash(relTask.step.theme),
          icon: relTask.icon
            ? `${process.env.STRAPI_URL}${relTask.icon.url}`
            : '',
        };
      });
      const bibliography = task.bibliography.map(bibliography => {
        return {
          ...bibliography,
          type: bibliography.type
            .replace('ComponentBibliografia', '')
            .toLowerCase(),
        };
      });

      return {
        name: content.name,
        bibliography: bibliography,
        theme: camelCaseToDash(task.step.class_selector),
        relationalsTasks: relationalsTasks,
      };
    })[0];
    const relationalTaskLabel = response.relationalTasklabels[0].contents
      .filter(content => content.language.abbr === language)[0]
      .sub_contents.reduce(
        (obj, val) => ({
          ...obj,
          [val.key]: val.value,
        }),
        {}
      );
    const investigarMasLabel = response.referencelabels[0].contents
      .filter(content => content.language.abbr === language)[0]
      .sub_contents.reduce(
        (obj, val) => ({
          ...obj,
          [val.key]: val.value,
        }),
        {}
      );
    const modalLabel = response.modallabels[0].contents
      .find(content => content.language.abbr === language)
      .sub_contents.reduce(
        (obj, val) => ({
          ...obj,
          [val.key]: val.value,
        }),
        {}
      );

    return {
      content: dataFiltered,
      label: {
        relationalTaskLabel: {
          title: relationalTaskLabel
            ? relationalTaskLabel.relationalTasksTitle
            : '',
          subTitle: relationalTaskLabel
            ? relationalTaskLabel.relationalTasksSubTitle
            : '',
        },
        investigarMasLabel: {
          title: investigarMasLabel ? investigarMasLabel.referenceTitle : '',
        },
        modal: modalLabel ? modalLabel.MessageModal : '',
      },
    };
  } catch (e) {
    errorHandler(e);
  }
};

const fetchLoginData = async language => {
  const query = `
  query LoginLabel {
    label: interfaceContents(where: { title: "login" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }
  `;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;
    if (response.errors) {
      throw response.errors;
    }

    const dataFiltered = response.label[0].contents
      .find(content => content.language.abbr === language)
      .sub_contents.reduce(
        (obj, val) => ({
          ...obj,
          [val.key]: val.value,
        }),
        {}
      );

    return {
      label: {
        email: {
          label: dataFiltered ? dataFiltered.emailLabel : '',
          message: dataFiltered ? dataFiltered.emailMessage : '',
        },
        password: {
          label: dataFiltered ? dataFiltered.passwordLabel : '',
          message: dataFiltered ? dataFiltered.passwordMessage : '',
        },
        remainder: {
          label: dataFiltered ? dataFiltered.remainderLabel : '',
        },
        submitButton: {
          label: dataFiltered ? dataFiltered.submitButton : '',
        },
        error: {
          label: dataFiltered ? dataFiltered.errorMessage : '',
        },
        title: {
          label: dataFiltered ? dataFiltered.title : '',
        },
        subtitle: {
          label: dataFiltered ? dataFiltered.subtitle : '',
        },
        forgot: {
          label: dataFiltered ? dataFiltered.forgot : '',
        },
        noAccount: {
          label: dataFiltered ? dataFiltered.noAccountLabel : '',
        },
        registerLink: {
          label: dataFiltered ? dataFiltered.registerLinkLabel : '',
        },
      },
    };
  } catch (e) {
    errorHandler(e);
  }
};

const mutationLogin = async ({
  credentials: { email = '', password = '' },
}) => {
  const mutation = `
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        username
        email
        front_admin
        heatmap_view
        progress
        role{
          type
          name
        }
      }
    }
  }
  `;
  const api = createInstance();
  const { data } = await api.post('', {
    query: mutation,
    variables: {
      input: {
        identifier: email,
        password: password,
      },
    },
  });
  const response = data.data;
  if (data.errors) {
    throw data.errors;
  }

  return {
    jwt: response ? response.login.jwt : '',
    user: {
      username: response ? response.login.user.username : '',
      email: response ? response.login.user.email : '',
      role: response ? response.login.user.role.type : '',
      roleName: response ? response.login.user.role.name : '',
      comment: response ? response.login.user.front_admin : '',
      heatmap: response ? response.login.user.heatmap_view : '',
      progress: response ? response.login.user.progress : '',
    },
  };
};

const fetchPremiumMenuContentData = async (language, token) => {
  const query = `
  query PremiumContent {
    title: staticContents(where: { uid_name: "premium-content" }) {
      contents {
        title
        language {
          abbr: abbreviation
        }
        sub_contents {
          title
          content
        }
      }
    }
    menu: premiumContents {
      uid_title
      destacado
      contents {
        language {
          abbr: abbreviation
        }
        title
        preview
        categoria
        files {
          name
          url
        }
        url_video
        url_subtitle
        img_before{
          url
        }
        img_after{
          url
        }
      }
    }
  }
  `;
  const api = createInstance(token);
  const { data } = await api.post('', { query });
  const response = data.data;
  if (data.errors) {
    const blockedUser = data.errors.find(
      error =>
        error.extensions.exception.output.payload.error === 'Unauthorized'
    );
    if (blockedUser) {
      throw blockedUser.extensions.exception.output.payload.error;
    }
    throw data.errors;
  }
  const filteredTitle = response.title[0].contents.find(
    content => content.language.abbr === language
  );
  const filteredMenu = response.menu.map(menu => {
    const content = menu.contents.find(
      content => content.language.abbr === language
    );

    return {
      uid: menu.uid_title ? menu.uid_title : '',
      title: content ? content.title : '',
      destacado: menu ? menu.destacado : '',
      subcontents: content ? content.sub_contents : '',
      preview: content ? content.preview : '',
      imagen: content ? content.files : '',
      categoria: content ? content.categoria : '',
      urlVideo: content ? content.url_video : '',
      urlSub: content ? content.url_subtitle : '',
      imageBefore: content && content.img_before ? content.img_before : '',
      imageAfter: content && content.img_after ? content.img_after : '',
      language: language,
    };
  });
  return {
    listPro: filteredMenu,
    title: filteredTitle ? filteredTitle.title : '',
    description: filteredTitle ? filteredTitle.sub_contents[0].content : '',
  };
};

const fetchPremiumContent = async (token, language, uid) => {
  const query = `
  query PremiumContent($uid: String) {
    premiumContents(where: { uid_title: $uid }) {
      contents { 
        language {
          abbr: abbreviation
        }
        categoria
        title
        content
        files {
          name
          url
        }
        url_video
        url_subtitle
        img_before{
          url
        }
        img_after{
          url
        }
      }
    }
  }
  `;
  const api = createInstance(token);
  const { data } = await api.post('', { query, variables: { uid: uid } });

  const response = data.data;
  if (data.errors) {
    throw data.errors;
  }

  const filteredData = response.premiumContents[0].contents.find(
    content => content.language.abbr === language
  );

  return {
    title: filteredData ? filteredData.title : '',
    content: filteredData ? filteredData.content : '',
    categoria: filteredData ? filteredData.categoria : '',
    files:
      filteredData && filteredData.files
        ? filteredData.files.map(file => ({
            url: `${process.env.STRAPI_URL}${file.url}`,
            ...file,
          }))
        : [],
    urlVideo: filteredData ? filteredData.url_video : '',
    urlSub: filteredData ? filteredData.url_subtitle : '',
    imageBefore:
      filteredData && filteredData.img_before ? filteredData.img_before : '',
    imageAfter:
      filteredData && filteredData.img_after ? filteredData.img_after : '',
    language: language,
  };
};

const fetchForgotPasswordData = async language => {
  const query = `
  query ForgotPasswordLabel {
    interfaceContents(where: { title: "recuperar-contraseña" }) {
      contents {
        language {
          abbr: abbreviation
        }
        sub_contents {
          key
          value
        }
      }
    }
  }
  `;
  const api = createInstance();
  const {
    data: { data, errors },
  } = await api.post('', { query });
  if (errors) {
    throw errors;
  }

  const filteredData = data.interfaceContents[0].contents.find(
    content => content.language.abbr === language
  );
  const title = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'title').value
    : '';
  const recoveryIntro = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'recoveryIntro')
        .value
    : '';
  const emailLabel = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'emailLabel').value
    : '';
  const button = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'button').value
    : '';
  const messageOk = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'messageOk').value
    : '';
  const messageFail = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'messageFail').value
    : '';
  return {
    label: {
      title,
      recoveryIntro,
      emailLabel,
      button,
      messages: {
        ok: messageOk,
        fail: messageFail,
      },
    },
  };
};

const mutationForgotPassword = async (email, lang) => {
  const api = createInstance('', '');
  const { data } = await api.post('users/recoverPassword', {
    email,
    lang,
  });
  console.debug('data', data);

  return data.ok;
};

const fetchPasswordRecoveryData = async language => {
  const query = `
    query ResetPasswordLabel {
      label: interfaceContents(where: { title: "nueva-contraseña" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });
  const filteredData = data.label[0].contents.find(
    content => content.language.abbr === language
  );

  const password = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'passwordLabel')
        .value
    : '';
  const passwordConfirmation = filteredData
    ? filteredData.sub_contents.find(
        label => label.key === 'passwordConfirmationLabel'
      ).value
    : '';
  const button = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'buttonLabel').value
    : '';
  const title = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'titleLabel').value
    : '';
  const messageFail = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'messageFail').value
    : '';
  const diffPassLabel = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'diffPassLabel')
        .value
    : '';
  const rulePassLabel = filteredData
    ? filteredData.sub_contents.find(label => label.key === 'rulePassLabel')
        .value
    : '';

  return {
    label: {
      password,
      passwordConfirmation,
      button,
      title,
      diffPassLabel,
      rulePassLabel,
      messageFail,
    },
  };
};

const mutationPasswordRecovery = async ({
  password,
  passwordConfirmation,
  code,
  currentLang,
}) => {
  const query = `
  mutation PasswordRecovery($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        username
        email
      }
    }
  }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', {
    query,
    variables: { password, passwordConfirmation, code, lang: currentLang },
  });
  return data;
};

const getHeatmapsLocation = async location => {
  const query = `
  query($location: String){
    heatmaps(where:{content_contains:$location}, limit: 1000, sort:"created_at:desc"){
      x
      y
      window_width
      window_height
    }
  }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query, variables: { location } });
  return data.heatmaps;
};

const fetchErrorMessagePremiumData = async language => {
  const query = `
  query PremiumErrorMessage {
    message: interfaceContents(where:{ title: "errores" }) {
      contents {
        language {
          abbr :abbreviation
        }
        labels: sub_contents {
          key
          value
        }
      }
    }
  }
  `;

  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const filteredData = data.message[0].contents.find(
    content => content.language.abbr === language
  );
  const message = filteredData.labels.find(
    label => label.key === 'premiumRevoke'
  );
  return message ? message.value : '';
};

const errorHandler = e => {
  // Controla el error que puede retonar la respuesta de la API
  if (e.response) {
    // client received an error response (5xx, 4xx)
    console.error('ha ocurrido un error en la obteción de los datos');
    console.error(e.response);
    switch (e.response.status) {
      case 404:
        throw new Error('No se ha encontrado información');
      case 500:
        throw new Error('Ha ocurrido un error en el servidor');
      default:
        break;
    }
  } else if (e.request) {
    // client never received a response, or request never left
    console.error('no se ha recibido una respuesta a tiempo');
    throw new Error('No se logro obtener informacion del servidor');
  } else {
    // anything else
    console.error(e);
    throw new Error('Ha ocurrido un problema, porfavor re intente');
  }
};

const insertHeatMapData = async data => {
  const api = createInstance();
  const query = {
    query: `
    mutation CreateHeatMap($input: createHeatmapInput) {
      createHeatmap(input: $input) {
        heatmap {
          x
          y
          ip
          content
          user_agent
          window_width
          window_height
          action
        }
      }
    }
    `,
    variables: {
      input: {
        data: {
          x: data.x,
          y: data.y,
          ip: data.ip,
          content: data.content,
          user_agent: data.user_agent,
          window_width: data.window_width,
          window_height: data.window_height,
          action: data.action,
        },
      },
    },
  };
  try {
    const { data } = await api.post('', query);
    const response = data.data;
    return response;
  } catch (e) {
    errorHandler(e);
  }
};

const fetchMyCountData = async (token, language) => {
  const query = `
    query MyProfile {
      labels: interfaceContents(where: { title: "mi-cuenta" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
      profile: me{
        username
        email
        user{
          name
        }
      }
    }
  `;
  const api = createInstance(token);
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : null;
  interfaceData.map(label => (labels[label.key] = label.value));

  return {
    label: labels,
    me: data.profile,
  };
};

const updateMyCountData = async (token, email, name) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/updateData', { email, name });
  console.debug('data', data);

  return { data };
};

const updatePasswordProfile = async (token, password, newPassword) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/updatePassword', {
    password,
    newPassword,
  });
  console.debug('data', data);

  return { data };
};

const fetchPlansData = async (token, language) => {
  const query = `
    query Plans {
      planes(where:{visible:true}){
        price_usd
        offer_price_usd
        price_clp
        offer_price_clp
        user_limit
        months_of_duration
        id
        id_paypal
        description_plan{
          lenguaje{
            abbr: abbreviation
          }
          title
          subtitle
          description
        }
      }
      labels: interfaceContents(where: { title: "planes" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance(token);
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  console.debug('labels', response.labels);

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  response.planes.map(
    plan =>
      (plan.description_plan = plan.description_plan.find(
        desc => desc.lenguaje.abbr === language
      ))
  );

  const plans = response.planes.length > 0 ? response.planes : null;

  return {
    plans: plans,
    labels: labels,
  };
};

const restPaySelected = async (token, plan, method, subid = '') => {
  console.debug('llamado', plan, method);
  const api = createInstance(token, '');
  const data = await api.post('planes/payment', {
    plan: plan,
    method: method,
    subid: subid,
  });

  return data;
};

const fetchPlanSubscribe = async (token, plan, language) => {
  const query = `
    query PayPlan ($plan: String!) {
      payPlans(where: {id_pay: $plan }){
        id
        id_pay
        date_pay
        start_date
        end_date
        amount
        id_subscription
        id_plan{
          id
          description
          price_usd
          offer_price_usd
          price_clp
          offer_price_clp
          user_limit
          months_of_duration
        }
        type_pay{
          id
          name
        }
        status_pay{
          id
          status
        }
      }
      labels: interfaceContents(where: { title: "pago" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
      me{
        role{
          id
          name
        }
      }
    }
  `;
  const api = createInstance(token);
  const {
    data: { data },
  } = await api.post('', { query, variables: { plan } });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  return {
    labels: labels,
    payPlans: data.payPlans ? data.payPlans[0] : null,
    userRole: data.me || null,
  };
};

const fetchMyPlan = async (token, language) => {
  const query = `
    query MyPlan {
      me{
        pago:user{
          id_pay
        }
      }
    }
  `;
  const api = createInstance(token);
  const {
    data: { data },
  } = await api.post('', { query });

  console.debug('plan', data.me.pago.id_pay);
  const queryData = `
    query MyPlanData($id_pay: String) {
      plan: payPlans(where:{id_pay: $id_pay}){
        id_pay
        end_date
        id_subscription
        status_pay{
          id
        }
        type_pay{
          name
        }
        id_plan{
          price_usd
          price_clp
          months_of_duration
          user_limit
          description_plan{
            lenguaje{
              abbr: abbreviation
            }
            title
            subtitle
            description
          }
        }
      }
      labels: interfaceContents(where: { title: "mi-plan" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;

  const datos = await api.post('', {
    query: queryData,
    variables: { id_pay: data.me.pago.id_pay },
  });

  const response = datos.data.data;
  console.debug('datos', datos);
  const labels = {};
  let planResumen = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));

  console.debug('labels', labels);

  if (response.plan.length > 0) {
    const planTMP = response.plan[0];
    planResumen.id = planTMP.id_pay;
    planResumen.end_date = planTMP.end_date;
    planResumen.method = planTMP.type_pay.name;
    const descTMP = planTMP.id_plan.description_plan.find(
      val => val.lenguaje.abbr === language
    );
    console.debug('descTMP', descTMP);
    planResumen.description = descTMP.description;
    planResumen.title = descTMP.title;
    planResumen.subtitle = descTMP.subtitle;
    planResumen.language = descTMP.lenguaje.abbr;

    planResumen.months_of_duration = planTMP.id_plan.months_of_duration;
    planResumen.price_clp = planTMP.id_plan.price_clp;
    planResumen.price_usd = planTMP.id_plan.price_usd;
    planResumen.user_limit = planTMP.id_plan.user_limit;
    planResumen.active =
      !planTMP.id_subscription ||
      !planTMP.status_pay ||
      planTMP.status_pay.id != '4'
        ? false
        : true;
  } else {
    planResumen = null;
  }
  console.debug('planResumen', planResumen);

  // const plan = response.plan.length > 0
  // ? response.plan[0].id_plan
  // :{};

  // console.debug('plan', plan);

  // plan.description = plan.description_plan.find(val => val.lenguaje.abbr === language);

  return { plan: planResumen, labels: labels };
};

const fetchGroupMembers = async (token, language) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/getGroup');
  console.debug('users', data.users);

  const querylabels = `
    query GroupLabels {
      labels: interfaceContents(where: { title: "cuenta-grupal" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;

  const apiLabels = createInstance(token);

  const datos = await apiLabels.post('', { query: querylabels });

  const response = datos.data.data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));

  console.debug('labels', labels);

  return { users: data.users, plan: data.plan, labels: labels };
};

const addGroupMembers = async (token, language, email) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/addUserToGroup', {
    email: email,
    lang: language,
  });
  console.debug('data', data);

  return { data };
};

const removeGroupMembers = async (token, email) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/removeUserToGroup', { email: email });
  console.debug('data', data);

  return { data };
};

const unsubscribePlan = async token => {
  const api = createInstance(token, '');
  const { data } = await api.post('planes/unsuscribe');
  console.debug('data unsubscribePlan', data);
  //  //  no es necesario obtener el rol, cuando termina la suscripción este se modifica
  // const query = `
  //   query role{
  //     me{
  //       role{
  //         id
  //         name
  //       }
  //     }
  //   }
  // `;
  // const api2 = createInstance(token);
  // const userRole = await api2.post('', { query });
  // console.debug('datarole unsubscribePlan', userRole);

  // return { data, userRole: userRole.data.me };
  return { data };
};

const fetchLabelsCheckIn = async language => {
  const query = `
    query labels{
      labels: interfaceContents(where: { title: "registro" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  return { labels: labels };
};

const registerNewUser = async ({ name, email, lang = 'ES' }) => {
  const api = createInstance('', '');
  const { data } = await api.post('users/registerNewUser', {
    email,
    name,
    lang,
  });
  console.debug('data', data);

  return data;
};

const statusPayPlan = async token => {
  const api = createInstance(token, '');
  const { data } = await api.post('pay-plans/statusPayPlan');
  console.debug('data', data);

  return data;
};

const statusPlan = async token => {
  const api = createInstance(token, '');
  const { data } = await api.post('pay-plans/statusPlan');
  console.debug('data', data);

  return data;
};

const isPlanActive = async token => {
  const api = createInstance(token, '');
  const { data } = await api.post('pay-plans/isPlanActive');
  return data.status_pay;
};

const fetchLabelsInactivePlan = async language => {
  const query = `
    query labels{
      labels: interfaceContents(where: { title: "plan-inactivo" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  return { labels: labels };
};
const addComment = async (token, comentario, contenido) => {
  const api = createInstance(token, '');
  const { data } = await api.post('comentarios/createComment', {
    comentario: comentario,
    contenido: contenido,
  });
  console.debug('data', data);
  return { data };
};
const getComments = async contenido => {
  const api = createInstance('', '');
  const { data } = await api.post('comentarios/getComments', {
    contenido: contenido,
  });
  console.debug('data', data);
  return { data };
};

const fetchLabelsComments = async language => {
  const query = `
    query labels{
      labels: interfaceContents(where: { title: "comentarios" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  return { labels: labels };
};

/**
 * Obtiene los datos para la pagina 'antes de comenzar'
 * @param {String} language
 *
 *
 * @returns {Object} obj.contents
 * @returns {Object} obj.interfaces
 * @returns {Object} obj.languages
 */
const fetchLandingPageData = async language => {
  const query = `
    {
      
      languages {
        name
        abbr: abbreviation
      }
      landingPage{
        contenido{
          language{
            abbr: abbreviation
          }
          title
          sub_title
          banner{
            url
          }
          url_video
          methodology{
            title
            sub_title
            methodology_list{
              title
              sub_title
            }
          }
          why{
            title
            sub_title
            why_list{
              title
              sub_title
              icon{
                url
              }
            }
          }
          ready{
            title
            sub_title
          }
          result{
            title
            result_list{
              quote
              name
              position
              photo{
                url
              }
            }
          }
        }
      }
      labels: interfaceContents(where: { title: "landing-page" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }`;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;
    const labels = {};
    const langs = response.languages;
    const lang = language || langs[0].abbr;

    if (response.errors) {
      throw response.errors;
    }

    const contents =
      response.landingPage.contenido.find(
        contentsVal => contentsVal.language.abbr === lang
      ) || null;

    // console.debug('labels', response.labels);

    const interfaceData =
      response.labels.length > 0
        ? response.labels[0].contents.find(val => val.language.abbr === lang)
            .sub_contents
        : {};
    console.debug('interfaceData', interfaceData);

    interfaceData.map(label => (labels[label.key] = label.value));
    console.debug('labels', labels);

    return {
      contents: contents,
      interfaces: labels,
      languages: langs,
    };
  } catch (e) {
    errorHandler(e);
  }
};

/**
 * asigna el plan free al usuario que lo solicita
 * @param {*} token
 * @returns
 */
const assignPlanFree = async token => {
  const api = createInstance(token, '');
  const { data } = await api.post('pay-plans/assignPlanFree');
  console.debug('assignPlanFree', data);
  return data;
};

/**
 * Obtiene los datos para la pagina 'antes de comenzar'
 * @param {String} language
 *
 *
 * @returns {Object} obj.contents
 * @returns {Object} obj.interfaces
 * @returns {Object} obj.languages
 */
const fetchLogos = async () => {
  const query = `
  {
    logo{
      blanco{
        url
      }
      amarillo{
        url
      }
      negro{
        url
      }
    }
  }`;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;

    if (response.errors) {
      throw response.errors;
    }

    return {
      logos: response.logo || null,
    };
  } catch (e) {
    errorHandler(e);
  }
};

const fetchLabelContact = async language => {
  const query = `
    query labels{
      labels: interfaceContents(where: { title: "contacto" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));
  console.debug('labels', labels);

  return { labels: labels };
};

const sendContactMessage = async ({
  name,
  email,
  messageContact,
  lang = 'ES',
}) => {
  const api = createInstance('', '');
  const { data } = await api.post('users/sendContactMessage', {
    email,
    name,
    messageContact,
    lang,
  });
  console.debug('data', data);

  return data;
};

const fetchFooterData = async language => {
  const query = `
    query data{
      labels: interfaceContents(where: { title: "pie-de-pagina" }) {
        contents {
          language {
            abbr: abbreviation
          }
          sub_contents {
            key
            value
          }
        }
      }
      subcontents: staticContents(where: { uid_name: "nosotros" }){
        contents{
          language {
            abbr: abbreviation
          }
          sub_contents{
            title
            id
          }
        }
      }
    }
  `;
  const api = createInstance();
  const {
    data: { data },
  } = await api.post('', { query });

  const response = data;
  const labels = {};

  const interfaceData =
    response.labels.length > 0
      ? response.labels[0].contents.find(val => val.language.abbr === language)
          .sub_contents
      : {};

  const subcontents =
    response.subcontents.length > 0
      ? response.subcontents[0].contents.find(
          val => val.language.abbr === language
        ).sub_contents
      : {};
  interfaceData.map(label => (labels[label.key] = label.value));

  return { labels: labels, subcontents: subcontents };
};

const updateMyProgress = async (token, progress) => {
  const api = createInstance(token, '');
  const { data } = await api.post('users/updateMyProgress', {
    progress: JSON.stringify(progress),
  });
  console.debug('assignPlanFree', data);
  return data;
};

/**
 * Obtiene los datos para la pagina 'antes de comenzar'
 * @param {String} language
 *
 *
 * @returns {Object} obj.contents
 * @returns {Object} obj.interfaces
 * @returns {Object} obj.languages
 */
const fetchLandingPageComponentsData = async language => {
  const query = `        
    query secciones{
      languages {
        name
        abbr: abbreviation
      }
      seccionesLandings(sort:"order:asc", where:{enabled:true}){
        order
        color:background_color
        titulo:lpc_titulo{
          componente:title_component{
            lenguaje{
              abbr:abbreviation
            }
            title
            sub_title
          }
        }
        separador:lpc_separator{
          color
        }
        carrusel_txt:lpc_carousel_text{
          componente:carousel_text{
            lenguaje{
              abbr:abbreviation
            }
            lista:carousel_text{
              title
              subtitle
            }
          }
        }
        carrusel_imagen:lpc_carousel_image{
          componente:carousel_images{
            lenguaje{
              abbr:abbreviation
            }
            lista:carousel_images{
              title
              text
              image{
                url
              }
            }
          }
        }
        carrusel_comentarios:lpc_carousel_comment{
          componente:carousel_comments{
            lenguaje{
              abbr:abbreviation
            }
            lista:carousel_comments{
              title
              subtitle
              comment
              image{
                url
              }
            }
          }
        }
        listas:lpc_list{
          componente:lpc_list_item{
            lenguaje{
              abbr:abbreviation
            }
            lista:lpc_list{
              icon{
                url
              }
              title
              content
            }
          }
        }
        video:lpc_video{
          componente:lpc_video{
            lenguaje{
              abbr:abbreviation
            }
            url_video
            url_subtitle
            height
            width
            img_before{
              url
            }
            img_after{
              url
            }
          }
        }
        imagen:lpc_image{
          componente:lpc_image{
            lenguaje{
              abbr:abbreviation
            }
            image{
              url
            }
            height
            width
          }
        }
        banner:lpc_banner{
          componente:lpc_banner{
            lenguaje{
              abbr:abbreviation
            }
            lista:imagen{
              url
            }
          }
        }
        botones:lpc_button{
          componente:lpc_buttons{
            lenguaje{
              abbr:abbreviation
            }
            lista:lpc_btn{
              label
              link
              color
            }
          }
        }
      }  
    }
`;
  try {
    const api = createInstance();
    const { data } = await api.post('', { query });
    const response = data.data;
    const langs = response.languages;
    const lang = language || langs[0].abbr;

    if (response.errors) {
      throw response.errors;
    }

    const contents = response.seccionesLandings;
    const result = [];
    // console.debug("contents", contents);
    contents.map(sec => {
      const newSec = {};

      const {
        titulo,
        separador,
        carrusel_txt,
        carrusel_imagen,
        carrusel_comentarios,
        listas,
        video,
        imagen,
        banner,
        botones,
        color,
      } = sec;
      // console.debug("sec", sec);
      newSec.color = color;

      if (titulo) {
        // console.debug("titulo", titulo);
        newSec.titulo =
          titulo.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      if (separador) {
        newSec.separador = separador;
      }

      if (carrusel_txt) {
        newSec.carrusel_txt =
          carrusel_txt.componente.find(c => c.lenguaje.abbr === language) ||
          null;
      }

      if (carrusel_imagen) {
        newSec.carrusel_imagen =
          carrusel_imagen.componente.find(c => c.lenguaje.abbr === language) ||
          null;
      }

      if (carrusel_comentarios) {
        newSec.carrusel_comentarios =
          carrusel_comentarios.componente.find(
            c => c.lenguaje.abbr === language
          ) || null;
      }

      if (listas) {
        newSec.listas =
          listas.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      if (video) {
        newSec.video =
          video.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      if (imagen) {
        newSec.imagen =
          imagen.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      if (banner) {
        newSec.banner =
          banner.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      if (botones) {
        newSec.botones =
          botones.componente.find(c => c.lenguaje.abbr === language) || null;
      }

      result.push(newSec);
    });

    return {
      contents: result,
      languages: langs,
    };
  } catch (e) {
    console.debug('error', e);
    errorHandler(e);
  }
};
