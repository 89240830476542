import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'gatsby';

import { api } from '../../services';
import { setLogos } from '../../store/actions/config';

const Logo = ({ icono }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  const logosConf = useSelector(({ config }) => config.logos);

  const { fetchLogos } = api();

  useEffect(() => {
    if (!logosConf) {
      const fetchData = async () => {
        try {
          const { logos } = await fetchLogos();
          await dispatch(setLogos(logos));
          setLogo(process.env.STRAPI_URL + logos[icono].url);
          console.debug('logo', logo);
        } catch (e) {
          // si ocurre un error el logo muestra una imagen por defecto mediante css
          // setLoading(false);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setLogo(process.env.STRAPI_URL + logosConf[icono].url);
      setLoading(false);
    }
  }, []);

  return (
    <picture className="logo p-2">
      {!loading && logo && (
        <Link
          id="logo"
          className="btn"
          to="/landing"
          style={{ backgroundImage: `url("${logo}")` }}
        ></Link>
      )}
    </picture>
  );
};

export default Logo;
