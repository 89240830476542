import React from 'react';
import Logo from '../layout/Logo';
import MenuHamburguer from '../layout/MenuHamburguer';

const Header = props => {
  let logo;
  switch (props.logo) {
    case 'amarillo':
      logo = <Logo icono="amarillo" />;
      break;
    case 'blanco':
      logo = <Logo icono="blanco" />;
      break;
    case 'negro':
      logo = <Logo icono="negro" />;
      break;
  }
  return (
    <div className="header-container">
      {logo}
      <MenuHamburguer
        isActive={props.isActive}
        to={props.to}
        previous={props.previous}
      />
    </div>
  );
};

export default Header;
