import React from 'react';

const Loading = ({ iconColor, className }) => {
  const colors = {
    amarillo: 'icon-defi',
  };
  const color = iconColor ? colors[iconColor] : null;
  return (
    <div className={`fa-2x ${className || ''}`}>
      <i className={`fas fa-inverse fa-spinner fa-spin ${color || ''}`}></i>
    </div>
  );
};

export default Loading;
