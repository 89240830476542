export const camelCaseToDash = myStr => {
  return myStr.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);
};

export const dashToCamelCase = myStr => {
  return myStr.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};

export const basicDateFormat = date => {
  const dt = new Date(date);
  console.debug('date', date, dt);
  return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${dt
    .getFullYear()
    .toString()
    .padStart(4, '0')} ${dt
    .getHours()
    .toString()
    .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`;
};
